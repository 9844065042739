<template>
  <el-dialog
    :visible.sync="visibleDialog"
    title="查看受众特征报告"
    closeable
    :before-close="close"
    width="70%"
  >
    <div class="tabs-wrap">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          v-for="item in tabs"
          :label="item.name"
          :name="item.type"
          :key="item.type"
        >
        </el-tab-pane>
      </el-tabs>
      <div class="date-range">
        <el-link type="primary" :underline="false" @click="()=> $emit('editAdgroup')">
          <i class="el-icon-edit"></i>
          修改受众特征
        </el-link>
      </div>
    </div>
    <div class="date-picker-wrap">
      <div>
        <el-button size="small" :disabled="!canEditStatus()" @click="doUpdateAdGroupCriterion('ENABLED')">
          启用
        </el-button>
        <el-button size="small" :disabled="!canEditStatus()" @click="doUpdateAdGroupCriterion('PAUSED')">
          暂停
        </el-button>
        <el-button size="small" :disabled="!canReomve()" @click="doUpdateAdGroupCriterion('REMOVED')">
          删除
        </el-button>
      </div>
      <ad-date-range-pick v-model="dateRange" size="small"></ad-date-range-pick>
    </div>
    <el-table
      :height="600"
      :data="tableData"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="display_name" :label="nameLabel">
      </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <div class="dp-flex align-item-c">
            <span
              :class="[
                'small-circle',
                statusInfo(scope.row.status,'class')
              ]"
            ></span>
            <span style="margin-left: 8px">{{statusInfo(scope.row.status,'label')}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="negative" label="排除">
        <template slot-scope="scope">
          <div class="dp-flex align-item-c">
            <span style="margin-left: 8px">{{
                scope.row.negative? "已排除": "否"
              }}</span>
          </div>
        </template></el-table-column>
      <el-table-column prop="conversion" label="转化次数"> </el-table-column>
      <el-table-column prop="purchase" width="120" label="每次转化费用">
      </el-table-column>
      <el-table-column prop="clicks" label="点击次数"> </el-table-column>
      <el-table-column prop="impressions" label="展示次数"> </el-table-column>
      <el-table-column prop="ctr" label="点击率">
        <template slot-scope="{ row }">
          <span>
            {{ getRate(row.ctr) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="conversion_rate" label="转化率">
        <template slot-scope="{ row }">
          <span>
            {{ getRate(row.conversion_rate) }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="cpm" label="千次费用"> </el-table-column>
      <el-table-column prop="cpc" width="120" label="平均每次点击费">
      </el-table-column>
      <el-table-column prop="all_conversions" width="120" label="所有转化次数">
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { getAdsReport,updateAdGroupCriterion } from "@/api/google/adManagement";
import * as fieldsList from "./audienceFields";
import AdDateRangePick from "@/views/adManagement/components/AdDateRangePick";
export default {
  components: {
    AdDateRangePick,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    adset: {
      type: Object,
      default: () => ({}),
    },
    accountIds: {
      type: [Array, String],
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      prefix: "audience_",
      activeName: "age",
      tabs: [
        {
          type: "age",
          name: "年龄",
        },
        {
          type: "gender",
          name: "性别",
        },
        {
          type: "income_range",
          name: "家庭收入",
        },
        {
          type: "parental_status",
          name: "育儿状况",
        },
      ],
      dateRange: {
        actionAttributionWindows: [],
        dateRange: [],
      },
      tableData: [],
      multipleSelection: [],
    };
  },
  computed: {
    visibleDialog: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
      },
    },
    nameLabel() {
      const item = this.tabs.find((v) => v.type === this.activeName);
      return item.name;
    },
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.getList();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (localStorage.getItem("adDateRange")) {
      this.dateRange = { ...JSON.parse(localStorage.getItem("adDateRange")) };
    }
  },
  methods: {
    canEditStatus(){
      if(!this.multipleSelection)return false;
      return this.multipleSelection.every(i=>!i.negative && (i.status==='ENABLED'||i.status==='PAUSED'));
    },
    canReomve(){
      if(!this.multipleSelection)return false;
      return this.multipleSelection.every(i=> i.status!='REMOVED');
    },
    statusInfo(status,field){
      let map={
        "UNSPECIFIED":{label:"未指定",class:"bg-danger"},
        "UNKNOWN":{label:"未知",class:"bg-danger"},
        "ENABLED":{label:"启用",class:"bg-success"},
        "PAUSED":{label:"暂停",class:"bg-danger"},
        "REMOVED":{label:"已移除",class:"bg-danger"},
        "UNRECOGNIZED":{label:"不确定",class:"bg-danger"},
      };
      if(map[status]){
        return map[status][field];
      }
      return "";
    },
    close() {
      this.visibleDialog = false;
    },
    handleClick() {
      this.getList();
    },
    doUpdateAdGroupCriterion(status){
      if(!this.accountIds)return;
      let sel=this.multipleSelection;
      if(status===null && negitave===null)return;
      let params={
        customerId:this.accountIds[0],
        resourceNames:[],
        status:status
      };
      sel.forEach(i=>{
        params.resourceNames.push(i.resource_name);
      })
      this.$showLoading();
      updateAdGroupCriterion(params)
          .then((res) => {
            this.$hideLoading();
            if (res.code == 0) {
              this.$message.success("成功");
              sel.forEach(i=>{i.status=status;})
            }else{
              this.$message.error(res.comment);
            }
          })
          .catch((err) => {
            this.$hideLoading();
            this.$message.error("失败");
            console.log(err);
          });
    },
    async getList() {
      await this.$nextTick();
      this.$showLoading();
      const { prefix, activeName, accountIds, dateRange, adset } = this;
      const [date_start, date_end] = dateRange.dateRange;
      let params = {
        account_ids: accountIds,
        date_end,
        date_start,
        fields: fieldsList[activeName],
        filtering: [
          {
            field: "group_id",
            operator: "EQUAL",
            value: adset.id,
          },
        ],
        object_filtering: [],
        level: prefix + activeName,
        page_length: 50,
        sort: [],
        platform: "google",
      };
      delete params.account_id
      this.tableData = [];
      getAdsReport(params)
        .then((res) => {
          // console.log(res);
          this.$hideLoading();
          if (res.code == 0) {
            this.tableData = res.data.data;
          }
        })
        .catch((err) => {
          this.tableData = [];
          this.$hideLoading();
          console.log(err);
        });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getRate(num) {
      return num ? (Math.round(num * 10000) / 100).toFixed(2) + "%" : "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs-wrap {
  position: relative;
  .date-range {
    position: absolute;
    right: 10px;
    top: 0;
    height: 40px;
    line-height: 40px;
  }
}
.date-picker-wrap {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
</style>