export const fields = [
    "resource_name",
    "status", // 状态
    "negative",
    "display_name",
    "conversion", // 转化次数
    "clicks", // 点击量
    "ctr", // 点击率
    "conversion_rate", // 转化率
    "purchase",
    "cpc", // 平均每次点击费
    "all_conversions", // 所有转化
    "impressions", // 展示次数
    "cpm", // 千次费用
]

// 广告组查看受众特征报告-年龄
export const age = [
    "age_range_type",
    ...fields
]

// 广告组查看受众特征报告-男女
export const gender = [
    "gender_type",
    ...fields
]

// 广告组查看受众特征报告-家庭收入
export const income_range = [
    "income_range_type",
    ...fields
]

// 广告组查看受众特征报告-家庭收入
export const parental_status = [
    "parental_status_type",
    ...fields
]